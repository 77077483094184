import { Container, ListSubheader, Button, Box, Fab, List, debounce, SwipeableDrawer, styled, Stack, Alert } from '@mui/material';
import React from 'react';
import { Drafts, IosShare, Send } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CartItem from './Components/CartItem';
import AddressSelector from '../Components/AddressSelector';
import ShareQuote from './Components/ShareQuote';
import CartService from './Services/cartService';
import { grey } from '@mui/material/colors';
import AppBarBack from '../Layout/AppBarBack';



const Cart = (props) => {

    const [products, setProducts] = React.useState([]);
    const [openAddressDialog, setOpenAddressDialog] = React.useState(false);
    const coletaService = process.env.REACT_APP_COLETA_SERVICE;
    const [addresses, setAddresses] = React.useState([]);
    const [addressLoaded, setAddressLoaded] = React.useState(false);
    const [site, setSite] = React.useState(null);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [quoteId, setQuoteId] = React.useState(null);
    const [openSendOptions, setOpenSendOptions] = React.useState(false);
    const [sharedCartId,setSharedCartId] = React.useState(null);
    const nav = useNavigate();

    function changeQuantity(id, quantity) {
        const p = { ...products[id], "quantity": quantity };
        const newValue = { ...products };
        newValue[id] = p;
        setProducts(newValue);
        updateCart(id, quantity);
    }

    const updateCart = debounce((id, quantity) => {
        const token = localStorage.getItem("token");
        const data = { product: id, quantity: quantity };
        axios.put(`${coletaService}/cart/product`, data, { headers: { "Authorization": `Berear ${token}` } }).then((response) => {
            loadCart();
        });
    }, 1000)

    const updateCartAddress = (site) => {
        setSite(site);
        const token = localStorage.getItem("token");
        const data = { address: site._id };
        axios.put(`${coletaService}/cart/address`, data, { headers: { "Authorization": `Berear ${token}` } }).then((response) => { });

    }

    React.useEffect(() => {
        loadCart();
    }, []);

    function loadCart() {
        const p = CartService.loadCart();
        p.then((response) => {
            setProducts(response.data.products);
            const site = Array.isArray(response.data.address) ? null : response.data.address;
            setSite(site);
        })
        p.catch((error) => { console.log(error) });
    }

    function createQuote() {
        const quoteItems = Object.values(products);
        if (quoteItems.length == 0 || site == null) {
            alert("Escolha um endereço para sua coleta.")
        }
        else {
            const p = CartService.createQuote(site, quoteItems);
            p.then((response) => {
                setQuoteId(response.data.insertedId);
                setShareDialogOpen(true);
            })
        }
    }


    React.useEffect(() => {
        if (openAddressDialog) return;
        const token = localStorage.getItem("token");
        axios.get(`${coletaService}/v1/addresses`, { headers: { "Authorization": `Berear ${token}` } }).then((response) => {
            setAddresses(response.data);
            if (response.status != 200) throw new Error("Error");
        }).catch((error) => { console.log(error) });
    }, [openAddressDialog]);

    function shareQuote(e){
        e.preventDefault();
        const data = {
            title:"Enviar coleta de preços",
            text: "Você recebeu uma coleta de preços, acesse o link abaixo para respoder.",
            url:`https://coletefy.com/#/reply/${quoteId}`
        }
        navigator.share(data);
    }
    function shareCart(){
        CartService.shareCart().then( async (id) => {
            const shareData = {
            title: "Compartilhe sua lista de compras.",
            text: "Acesse o link para visualizar o carrinho. ",
            url: `https://coletefy.com/#/cart/${id}`,
          };

          try {
            await navigator.share(shareData);
          } catch (err) {
          }
        })
    }


    React.useEffect(() => { setAddressLoaded(true) }, [addresses]);
    React.useEffect(() => { if (!openAddressDialog) setAddressLoaded(false) }, [openAddressDialog]);
    React.useEffect(() => { setAddressLoaded(false) }, []);

    function openNewAddress() {
        nav("/address/new");
    }

    const StyledBox = styled('div')(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.applyStyles('dark', {
          backgroundColor: grey[800],
        }),
      }));


    return (
        <>
                <Box>
                    <AppBarBack title="Carrinho" goBack={() => nav("/quote")}/>
                    <Container sx={{ pb: 2, mt: 2, paddingBottom: "100px" }}>
                        {!site && <Alert severity='error'>Selecione um endereço</Alert> }
                        <ListSubheader>Obra</ListSubheader>

                        {addressLoaded && <AddressSelector onChange={updateCartAddress} onNewSite={() => openNewAddress()} addresses={addresses} selected={site} />}
                        <ListSubheader>Produtos</ListSubheader>
                        <List>
                            {Object.entries(products).map((a) => {
                                const e = a[1];
                                return <CartItem
                                    key={e.id}
                                    id={e.id}
                                    product={e?.produto}
                                    picture={e?.foto_webp}
                                    quantity={e?.quantity}
                                    brand={e?.marca}
                                    setQuantity={(v) => changeQuantity(e.id, v)}
                                    onClick={() => { }} />
                            })
                            }
                        </List>
                    </Container>
                    <Fab sx={{position:"fixed",bottom:"20px",right:"20px"}} color="primary" onClick={() => setOpenSendOptions(true)}><Send sx={{color:"white"}}/></Fab>
                </Box>
            <SwipeableDrawer onClose={() => setOpenSendOptions(false)} open={(openSendOptions)} onOpen={() => { }} anchor='bottom'>
                <Stack sx={{m:2}} spacing={1}>
                <Button endIcon={<Send/>} fullWidth variant='outlined' onClick={createQuote} disabled={!site || Object.entries(products).length == 0} >Enviar Coleta</Button>
                <Button endIcon={<Drafts/>} fullWidth variant='outlined' >Salvar Rascunho</Button>
                <Button endIcon={<IosShare/>} fullWidth variant='outlined' onClick={shareCart}>Compartilhar Carrinho</Button>
                </Stack>
            </SwipeableDrawer>
            <ShareQuote open={shareDialogOpen} share={shareQuote} handleClose={() => { setShareDialogOpen(false) }} />
        </>
    );
};

export default Cart;