import { BookmarkTwoTone, BookmarkBorderTwoTone, Warning, ExpandMore } from '@mui/icons-material';
import { Avatar, Rating, IconButton, Box, Typography, CardActions, Chip, CardContent, Card, CardHeader, Alert, Button, Collapse, CircularProgress, Skeleton, Grid } from '@mui/material';
import React from 'react';

const ProductOptionsCardSkeleton = (props) => {
    return (
        <Grid item xs={12} md={12} lg={12} key={props.key}>
        <Card sx={{ m: 1 }}>
            <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" sx={{ width: "40px", height: "40px" }} />}
                title={<Skeleton animation="wave" variant='text'></Skeleton>}
                subheader={<Skeleton animation="wave" variant='text'></Skeleton>}
            />
            <Box>
                <Box sx={{ ml: 2,mr:2, display: "flex", flexDirection: "row"}}>
                    <Skeleton variant='rectangular' animation="wave" sx={{width:"100%",height:"80px"}}></Skeleton>
                </Box>
            </Box>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                </IconButton>
            </CardActions>
        </Card>
        </Grid>
    );
};

export default ProductOptionsCardSkeleton;