import { ListSubheader, List, useTheme, Dialog } from '@mui/material';
import React from 'react';
import SlideTransition from '../Components/SlideTransition';
import ProductOptions from './ItemReplies';
import QuoteProductListItem from './Components/QuoteProductListItem';
import { useNavigate, useParams } from 'react-router-dom';
import QuoteService from './Services/QuoteService';
import ProductOptionsCardSkeleton from './Components/ProductOptionCardSkeleton';

const Products = () => {
    const quoteId = useParams().id
    const [openProductDialog, setOpenProductDialog] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [quoteItems, setQuoteItems] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);


    const nav = useNavigate();

    function openReplies (quoteItemId){
        nav(`replies/${quoteItemId}`);
    }

    React.useEffect(() => {   
        QuoteService.getQuote(quoteId).then((quote) => {
            setQuoteItems(quote.items);
            setIsLoading(false);
        })
    },[]);

    return (
        <div>
            <ListSubheader>Produtos</ListSubheader>
            <List sx={{ pb: "100px" }}>
                { isLoading ? 
                [1,1,1,1,1].map((e,i) => <ProductOptionsCardSkeleton key={i}/>) :
                quoteItems.map((a, i) => {
                    return <QuoteProductListItem product={a} key={i} openProductDialog={() => openReplies(a._id)} />
                })
                }
            </List>
        </div>
    );
};

export default Products;