import { BookmarkTwoTone, BookmarkBorderTwoTone, Warning, ExpandMore } from '@mui/icons-material';
import { Avatar, Rating, IconButton, Box, Typography, CardActions, Chip, CardContent, Card, CardHeader, Alert, Button, Collapse, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { ProductImage } from '../../Components/ProductImage';

const ProductOptionCard = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const [option, setOption] = React.useState(props.product);
    const [rating, setRating] = React.useState(5);

    function renderAction(option) {
        if (props.isLoading)
            return <IconButton><CircularProgress size="30px" /></IconButton>
        else
            return <IconButton
                disabled={option.loading}
                onClick={() => props.selectOption(option.selected ? null : option.replyId)}>
                {option.selected ?
                    <BookmarkTwoTone color="primary" /> :
                    <BookmarkBorderTwoTone color='primary' />}
            </IconButton>

    }

    return (

        <Grid item xs={12} md={12} lg={12} key={option._id} >
            <Card>
                <CardHeader
                    avatar={<Avatar alt={option.repliedBy.company} src={option.repliedBy.profilePicture} />}
                    title={option.repliedBy.company}
                    subheader={
                        <Rating name="read-only"
                            value={rating}
                            readOnly
                            size="small" />}
                    action={renderAction(option)}
                />
                {option.isAlternativeProduct && <Alert icon={<Warning fontSize="inherit" sx={{ alignSelf: "center" }} />} severity="warning" sx={{ ml: 2, mr: 2, mb: 2 }}>
                    Este é um produto alternativo.
                </Alert>}
                <Box>
                    <Box sx={{ ml: 2, display: "flex", flexDirection: "row", gap: 1 }}>
                        <Avatar ><ProductImage src={option.replyProduct.picture} /></Avatar>
                        <Box sx={{ ml: 2, display: "flex", flexDirection: "column", flexGrow: 0 }}>
                            <Typography variant="body1" sx={{ overflow: "hidden", WebkitLineClamp: 2, textOverflow: "ellipsis" }}>{option.replyProduct.product}</Typography>
                            <Typography variant="caption">{option.replyProduct.brand}</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ mr: 2, ml: 2, mt: 1, display: "flex", flexDirection: "column", alignSelf: "center", flexGrow: 1 }}>
                        <Button variant='contained' sx={{ borderRadius: "30px" }}>
                            <Typography variant="body2" sx={{ alignSelf: "center" }}>R$ {option.price.toFixed(2)}</Typography>
                        </Button>
                    </Box>

                </Box>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                    </IconButton>
                    <Box sx={{ flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 1 }}>
                        {option.isBestPrice && <Chip label="Melhor Preço" variant="outlined" color="success" size="small" sx={{ alignSelf: "center" }} />}
                        {option.bestQuote && <Chip label="Melhor Coleta" variant="outlined" color="secondary" size="small" sx={{ alignSelf: "center" }} />}
                        {option.bestSuplyer && <Chip label="Melhor Fornecedor" variant="outlined" color="warning" size="small" sx={{ alignSelf: "center" }} />}
                    </Box>
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        {expanded ? <ExpandMore sx={{ rotate: "180deg" }} /> : <ExpandMore />}
                    </IconButton>
                </CardActions>     <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {option.aditionalInfo}
                        <Button variant="outlined" fullWidth size="small" sx={{ mt: 2 }}>Ver Produto</Button>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
};

export default ProductOptionCard;