import { ChevronRight } from '@mui/icons-material';
import { ListItem, IconButton, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import React from 'react';


const SiteListItem = ({ site }) => {
    
    return (
        <ListItem alignItems="flex-start"
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <ChevronRight />
                            </IconButton>}
                    >
                        <ListItemAvatar>
                            <Avatar alt={site.reference} src="/static/images/avatar/2.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={site.reference}
                            secondary={`${site.street}, ${site.number} - ${site.city}`}
                            secondaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
    );
};

export default SiteListItem;