import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, Backdrop, TextField } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { debounce } from '@mui/material/utils';
import { useOutletContext } from 'react-router-dom';
import { CropFree, QrCodeScanner } from '@mui/icons-material';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
}));


const Search = styled('div')(({ theme }) => ({
  border: "solid 1px",
  borderRadius: "20px",
  borderColor: "white",
  position: 'relative',
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: "black"
  },
  marginRight: theme.spacing(2),
  marginLeft: "20px",
  width: 'calc(100vw - 40px)',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  caretColor: "black",
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


export default function SearchAppBar(props) {
  const [open, setOpen] = useOutletContext();
  const changeSearch = debounce((e) => props.onChange(e.target.value), 300);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <AppBar position="sticky" sx={{ height: 110 }}>

        <Toolbar>
          <IconButton color="inherit" onClick={() => setOpen(!open)}><MenuIcon sx={{ color: "white" }} /></IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
            {props.title}
          </Typography>

          <IconButton color="inherit" onClick={() => { props.openScanner() }}>
            <QrCodeScanner sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
        <Search onChange={changeSearch}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Buscar Produto..."
            inputProps={{ 'aria-label': 'search' }}
          /></Search>
      </AppBar>
      {props.children}
    </>
  );
}