import './App.css';
import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Reply from './Reply/Reply';
import Login from './Login';
import { ProtectedRoutes } from './ProtectedRoutes';
import Quotes from './Quotes/Quotes';
import NewQuote from './NewQuote/NewQuote';
import Cart from './NewQuote/Cart';
import Address from './Addresses/Address';
import Addresses from './Addresses/Addresses';
import Quote from './Quote/Quote';
import ItemReplies from './Quote/ItemReplies';
import Replies from './Replies/Replies';
import ReplyItem from './Reply/ReplyItem';
import Settings from './Settings/Settings';
import ReplyStepper from './Reply/ReplyStepper';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Welcome from './Welcome/Welcome';
import SharedCart from './NewQuote/SharedCart';
import PersonalDetails from './Settings/PersonalDetails';
import QRCodeScanner from './NewQuote/QRCodeScanner';
import { useTheme } from '@mui/material';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA98rbzkcFB8E1Svr7mmuAv5kpwaFOsRi0",
  authDomain: "colete-b6440.firebaseapp.com",
  projectId: "colete-b6440",
  storageBucket: "colete-b6440.appspot.com",
  messagingSenderId: "867168651052",
  appId: "1:867168651052:web:23fb1eaa15e0047c2c4e89",
  measurementId: "G-BT9N41P8D4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  const theme = useTheme();
  console.log(theme.palette.primary.main);
  
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/welcome" replace={true} />}/>
      <Route element={<ProtectedRoutes />}>
        <Route element={<Layout />} path="/">
          <Route index path="/quotes" element={<Quotes />} />
          <Route path="/reply/:id"  element={<ReplyStepper />} />
          <Route path="/addresses"  element={<Addresses />} />
          <Route path="/quote"  element={<NewQuote />} />
          <Route path="/replies"  element={<Replies />} />
          <Route path="/settings"  element={<Settings />} />
          <Route path="/barCodeScanner"  element={<QRCodeScanner />} />
          <Route path="/settings/personalDetails"  element={<PersonalDetails />} />
        </Route>

        <Route path="/quote/:id"  element={<Quote />} />
        <Route path="/quote/:id/replies/:itemId"  element={<ItemReplies />} />
        <Route path="/address/new"  element={<Address />} />
        <Route path="/cart/:sharedId"  element={<SharedCart />} />
        <Route path="/cart"  element={<Cart />} />
        <Route path="/reply/:id/item/:itemId"  element={<ReplyItem />} />
      </Route>
      <Route path ='/welcome' index element={<Welcome/>}/>
      <Route path='/login' element={<Login />}  />
    </Routes>
  );
}

export default App;
