import api from "../../API/api";

const ReplyService = {

    getReplyItem: (quoteId,itemId) => {
        return new Promise((resolve, reject) => {
            const p = api.get(`/v1/reply/${quoteId}/item/${itemId}`);
            p.then((response) => {
                resolve(response.data)
            });
            p.catch(e => {
                reject(e);
            });
        });
    },

    /**
     * 
     * @param {*} quoteId 
     * @param {*} itemId 
     * @param {{price:number,replyProduct:Id,observation:string,replyItem:boolean}} options 
     * @returns 
     */
    putReplyItem: (quoteId,itemId,options) => {
        return new Promise((resolve, reject) => {
            const p = api.put(`/v1/reply/${quoteId}/item/${itemId}`,options);  
            p.catch((e) => {
                reject(e);
            });
            p.then((response) => { 
                resolve(response.data);
            });
        });
    },
}

export default ReplyService;