import api from "../API/api";
// 'file' comes from the Blob or File API
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA98rbzkcFB8E1Svr7mmuAv5kpwaFOsRi0",
    authDomain: "colete-b6440.firebaseapp.com",
    projectId: "colete-b6440",
    storageBucket: "colete-b6440.appspot.com",
    messagingSenderId: "867168651052",
    appId: "1:867168651052:web:23fb1eaa15e0047c2c4e89",
    measurementId: "G-BT9N41P8D4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();


const UploadService = {
    /**
     * 
     * @param {File} file 
     */
    uploadFile: (file) => {
        const extension = file.name.substring(file.name.lastIndexOf("."));
        const filename = `${crypto.randomUUID()}${extension}`;
        const storageRef = ref(storage,filename );
        console.log(storageRef);
        return new Promise((resolve,reject) => {
            file.arrayBuffer().then(a => uploadBytes(storageRef, a, {}).then((e) => {
                resolve(`https://firebasestorage.googleapis.com/v0/b/${storageRef.bucket}/o/${storageRef.fullPath}?alt=media`)
            }))

        })
        // return new Promise((resolve, reject) => {
        //     api.post(`/v1/upload`, data).then((response) => {
        //         if (response.status != 200) throw new Error("Error");
        //         resolve(response.data);
        //     }).catch((error) => { reject(error) });
        // });

    }
}

export default UploadService;