import React from 'react';
import CartFab from '../NewQuote/Components/CartFab';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { AutoAwesome, Drafts,ListAltTwoTone } from '@mui/icons-material';
const BottomBarQuote = (props) => {
    var cartItems = 0;
    if(props?.cart !== undefined) {
        const products = props?.cart?.products ?? {};
        cartItems = Object.keys(products).length;
    }
    return (
        <div style={{ position: 'fixed', bottom: 0, height: "100px",  width: "100%"}}>
        <CartFab items={cartItems} onClick={props.goToCart} />
        <div style={{height:"45px",  backgroundImage:"linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,1))"}}></div>
        <BottomNavigation
            showLabels
            value={-1}
            onChange={(event, newValue) => {}}
        >
            <BottomNavigationAction label="Rascunhos" icon={<Drafts />} disabled />
            <BottomNavigationAction />
            <BottomNavigationAction label="Sugeridos" icon={<AutoAwesome />} />
        </BottomNavigation>
    </div>
    );
};

export default BottomBarQuote;