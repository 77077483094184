import React from 'react';
import QuoteListItem from './Components/QuoteListItem';
import { Fab, List, ListSubheader, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QuoteListItemSkeleton from './Components/ReplyListSkeleton';
import QuotesService from './Services/QuotesService';
import AppBarMenu from '../Layout/AppBarMenu';

const Quotes = () => {
    const nav = useNavigate();
    const [quotes, setQuotes] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);

    const openQuote = (id) => {
        nav(`/quote/${id}`);
    }

    React.useEffect(() => {
       const promise = QuotesService.getQuotes();
       promise.then((quotes) => {
            setQuotes(quotes);
            setIsLoading(false);
       })
    }, []);

    return (
        <AppBarMenu title="Coletas">
            <List sx={{ width: '100%' }} component="nav" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Coletas
                </ListSubheader>
            } >
                {isLoading ?
                [1,1,1,1,1].map((e,i) => <QuoteListItemSkeleton key={i}/>)
                :quotes.map((e, idx) => <QuoteListItem onClick={() => openQuote(e._id)} reference={e.reference} address={e.address} created_at={e.created_at} status={e.status} subtitle={e.subTitle} key={idx} />)}
            </List>
        </AppBarMenu>
    );
};

export default Quotes;