import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Container, Fab, List, ListItem, ListItemAvatar, ListItemText, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { Stack, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CurrencyFormatter from '../Components/CurrencyFormater';
import ReplyService from './Services/ReplyServices';

function Step4(props) {
    const nav = useNavigate();
    const total = props.reply.items.reduce((acc,i) => acc+=i.quantity*i.price,0);

    function sendReply() {
        ReplyService.postReply(props.reply.replyTo).then(() => {
            nav("/replies");
        })
    }

    return (
        <>
            <Button variant="outlined" fullWidth onClick={sendReply} disabled={props.reply.status != 'new'}>Enviar Resposta</Button>
            {props.reply.status == 'sent' && <Alert sx={{ mt: 2 }}>Essa resposta já foi enviada</Alert>}
            <Card sx={{ mt: 2, mb: 2 }}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" src={props.reply.profilePicture} />
                    }
                    title={props.reply.repliedBy}
                    subheader={props.reply.company} />
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        Observações
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {props.reply.observation ?? "Nenhuma Observação"}
                    </Typography>
                </CardContent>
            </Card>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            <TableCell >Produto</TableCell>
                            <TableCell align="right">Qtd</TableCell>
                            <TableCell align="right" width={60}>Preco</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.reply.items.map((item) => (
                            <TableRow
                                key={item._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="right">{item.product.product}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">{CurrencyFormatter(item.price)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow > 
                            <TableCell colSpan={2} align='right'>Total:</TableCell>
                            <TableCell>{CurrencyFormatter(total)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default Step4;