import React from 'react';
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"

export default (props) => {
  const [barcode, setBarCode] = React.useState(null);

  return <>
    <BarcodeScanner options={{ formats: ['ean_13','ean_8'] }} onCapture={props.detect} />
  </>
}