import React from 'react';
import AppBarMenu from '../Layout/AppBarMenu';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const nav = useNavigate();
    const goToPersonalDetails = () => nav("personalDetails"); 
    return (
        <AppBarMenu title="Preferencias">
            <List subheader={<ListSubheader>Ajustes Pessoais</ListSubheader>}>
                <ListItem onClick={goToPersonalDetails}  secondaryAction={<IconButton><ChevronRight /></IconButton>}>
                    <ListItemText primary={"Dados Pessoais."}
                        secondary={"Altere seus dados pessoais."} secondaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
                <Divider component="li" />
                <ListItem onClick={() => { }} secondaryAction={<IconButton><ChevronRight /></IconButton>}>
                    <ListItemText primary={"Configurações de Notificações"}
                        secondary={"Preferencias sobre notificações."} secondaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
                <Divider component="li" />
                <ListSubheader>Ajustes de Fornecedor</ListSubheader>
                <ListItem onClick={() => { }} secondaryAction={<IconButton><ChevronRight /></IconButton>}>
                    <ListItemText primary={"Região de Atuação"}
                        secondary={"Configure os lugares que você atende."} secondaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
                <Divider component="li" />
                <ListItem onClick={() => { }} secondaryAction={<IconButton><ChevronRight /></IconButton>}>
                    <ListItemText primary={"Portifólio de Produtos"}
                        secondary={"Quais produtos você trabalha?"} secondaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
                <Divider component="li" />
            </List>
        </AppBarMenu>
    );
};

export default Settings;