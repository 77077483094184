
import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { MuiTelInput } from 'mui-tel-input'
import { TextField, Container, Button, Stack, Avatar, Box, Dialog, DialogContent, DialogActions, IconButton, DialogTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Close, WhatsApp } from '@mui/icons-material';

export default function Login() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [phoneNumber, setPhoneNumber] = React.useState('+55');
    const [otp, setOtp] = React.useState('');
    const [requestSent, setRequestSent] = React.useState(false);
    const coletaService = process.env.REACT_APP_COLETA_SERVICE;
    const [phoneNumberError, setPhoneNumberError] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);

    function requestCode() {
        const isValid = validateAreaCode(phoneNumber);
        setRequestSent(true);
        setOpenDialog(true);
        axios.get(`${coletaService}/v1/otp/${phoneNumber}`).then((response) => {
            console.log(response.data);
        });
    }

    function confirmCode() {
        setRequestSent(true);
        axios.post(`${coletaService}/v1/otp/${phoneNumber}`, { otp }).then((response) => {
            localStorage.setItem('token', response.data);
            const url = searchParams.get('url') ?? '/quote';
            navigate(url);
        }).catch(e =>
            alert("Código Inválido")
        );
    }

    function validateAreaCode() {
        let value = phoneNumber;
        value = value.replaceAll(" ", "");
        const reg = new RegExp('^(\\+55)(([0-9]{10,11}))$');
        const reg2 = new RegExp('^(\\+1)([0-9]{10})');
        const e = !(reg.test(value) || reg2.test(value));
        setPhoneNumberError(e);
    }

    React.useEffect(() => validateAreaCode(), [phoneNumber])


    return (
        <>
            <Container sx={{ height: "80vh", display: "flex", width: "90vw", alignItems: "center" }}>
                <Stack sx={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }} spacing={2}>
                    <Box>
                        <Avatar sx={{ width: 100, height: 100, margin: "auto" }} alt="Remy Sharp" src="/gettaquote.jpg" />
                    </Box>
                    {
                        requestSent ?
                            <MuiOtpInput TextFieldsProps={{ inputProps: { inputMode: 'numeric' } }} value={otp} onChange={(e) => { setOtp(e) }} length={4} sx={{ maxWidth: "700px" }} /> :
                            <MuiTelInput error={phoneNumberError} helperText={phoneNumberError ? "Este não é um numero válido" : ""} onlyCountries={['BR', 'US']} label="Numero do Whatsapp com DDD" value={phoneNumber} onChange={(e) => { setPhoneNumber(e) }} sx={{ width: "100%", maxWidth: "700px" }} />
                    }
                    {!requestSent && <Button variant='contained' sx={{color:"white", width: "100%", maxWidth: "700px" }} onClick={requestCode} disabled={phoneNumberError}>Solicitar Código.</Button>}
                    {requestSent && <Button variant='contained' sx={{color:"white",  width: "100%", maxWidth: "700px" }} onClick={confirmCode}>Confirmar</Button>}
                    {requestSent && <Button variant='outlined' sx={{ width: "100%", maxWidth: "700px" }} onClick={() => setRequestSent(false)}>Solicitar outro codigo.</Button>}
                </Stack>
            </Container   >
            <Dialog open={openDialog} >
                <DialogTitle sx={{ "color": "black" }}>Atenção</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                   <Typography> Um código de acesso foi enviado para o <strong>WhatsApp:</strong> </Typography>
                   <Box sx={{justifyContent:"center",display:"flex",mt:2}}><Typography ><WhatsApp sx={{verticalAlign:"middle ",fontSize:"20px",color:"green"}}/> {phoneNumber}</Typography></Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setOpenDialog(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}