import { Add, ChevronRight } from '@mui/icons-material';
import { ListItem, IconButton, ListItemAvatar, Avatar, ListItemText, Divider, Button, Typography, Card, CardContent, CardMedia, CardActions } from '@mui/material';
import React from 'react';
import { ProductImage } from '../../Components/ProductImage';
import { CardHeader } from 'react-bootstrap';

const ProductListItem = (props) => {

    const addProduct = (e) => {
        e.stopPropagation();
        props.productAdd();
    }
    return (
        <Card sx={{ height: "100%",display:"flex",flexDirection:"column" }}>
            <CardHeader title="Product" />
            <CardMedia component="img" height="100" width="100"
                    onError={e => e.target["src"] = "/image.png"}
                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                    src={`https://firebasestorage.googleapis.com/v0/b/colete-b6440.appspot.com/o/webp%2F${props?.picture}?alt=media`} />
            <CardContent sx={{flexGrow:1}}>
                <Typography sx={{ textOverflow: "ellipsis", margin: "5px" }} variant='body1'>{props.product}</Typography>
                <Typography sx={{ alignContent: "center", flexGrow: 1, color: "gray", flexShrink: 0 }} variant="subtitle1" noWrap>{props.brand}</Typography>
            </CardContent>
            <CardActions sx={{justifyContent:"flex-end",display:"flex"}}>
                <Button size="small" onClick={props.onClick} variant="outlined">Ver Detalhes</Button>
                <Button endIcon={<Add/>} variant="outlined" size="small" onClick={addProduct}>Adicionar</Button>
            </CardActions>
        </Card>
        // <>

        // <div style={{display:"flex",flexDirection:"row",margin:"10px"}} onClick={props.onClick}>
        //     <div style={{flexGrow:0,marginRight:"10px",alignContent:"center"}}>
        //         <Avatar><ProductImage src={props?.picture} /></Avatar>
        //     </div>
        //     <div style={{flexGrow:1,display:"flex",flexDirection:"column"}}>
        //         <Typography noWrap sx={{textOverflow:"ellipsis",margin:"5px"}} variant='body1'>{props.product}</Typography>
        //         <div style={{display:"flex",flexDirection:"row"}}>
        //             <Typography sx={{alignContent:"center",flexGrow:1,color:"gray",flexShrink:0}} variant="subtitle1" noWrap>{props.brand}</Typography>
        //             <Button endIcon={<Add/>} variant="outlined" size="small" onClick={addProduct}>Adicionar</Button>
        //         </div>
        //     </div>
        //     </div>
        //     <Divider variant="fullWidth" component="li" />
        //     </>
    );
};

export default ProductListItem;