import { IconButton, Box, TextField, Stack, Container, Button, Typography } from '@mui/material';
import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import AppBarBack from '../Layout/AppBarBack';
import AddressService from './Services/AddressService';
import { useNavigate } from 'react-router-dom';

const Address = () => {
    const [reference, setReference] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [cepError, setCepError] = React.useState(false);
    const [uf, setUf] = React.useState('');
    const [city, setCity] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [number, setNumber] = React.useState('');
    const [complement, setComplement] = React.useState('');
    const [disabledSend, setDisableSend] = React.useState(true);

    const clearAddress = () => {
        setUf('');
        setCity('');
        setStreet('');
        setCepError(false);
    }

    const nav = useNavigate();

    function saveAddress() {
        const data = { reference, zipCode, uf, city, street, number, complement };
        const promise = AddressService.createAddress(data);
        promise.then(() => {
            nav(-1);
        });
    }


    React.useEffect(() => {
        const disable = (reference && zipCode && uf && city && street && number) ? false : true;
        setDisableSend(disable);
    })

    React.useEffect(() => {
        if (zipCode.length === 8){
            const promise = AddressService.fetchCep(zipCode);
            promise.then((data) => {
                if (data.erro){
                    clearAddress();
                    setCepError(true);
                }
                else{
                    setCepError(false);
                    setUf(data.uf);
                    setCity(data.localidade);
                    setStreet(data.logradouro);
                }});
        }
        else
            clearAddress();
    }, [zipCode]);



    return (
        <AppBarBack title="Nova Obra">
            <Container sx={{ mt: 2 }}>
                <Stack spacing={2} >
                    <Typography variant='h6'>Obra</Typography>
                    <TextField fullWidth name='Nome' label="Nome" value={reference} required onChange={e => setReference(e.target.value)} />
                    <TextField
                        error={cepError}
                        value={zipCode}
                        name='Cep' label="Cep" onChange={(e) => setZipCode(e.target.value)}
                        id="outlined-adornment-password"
                        inputProps={{ inputMode: 'numeric', maxLength: 8 }}
                        helperText={cepError ? 'Cep não encontrado' : ''}
                        InputProps={{ endAdornment: <IconButton><PlaceIcon /></IconButton> }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", gap: 2 }}>
                        <TextField name='UF' label="UF" disabled value={uf} />
                        <TextField name='Municipio' label="Municipio" disabled value={city} fullWidth />
                    </Box>
                    <TextField fullWidth name='Logradouro' label="Logradouro" disabled value={street} required />
                    <TextField type='number' inputProps={{ inputMode: 'numeric', pattern: "[0-9]{5}" }} fullWidth name='Numero' label="Numero" value={number} onChange={(e) => setNumber(e.target.value)} required />
                    <TextField fullWidth name='Complemento' label="Complemento" value={complement} onChange={(e) => setComplement(e.target.value)} />
                    <Button variant="outlined" onClick={saveAddress} disabled={disabledSend}>Salvar Nova Obra</Button>
                </Stack>
            </Container>
        </AppBarBack>
    );
};

export default Address;