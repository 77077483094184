import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

const ShareQuote = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Agora é só compartilhar.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{mb:2}}>
                    Sua coleta foi criada com sucesso! Compartilhe esta coleta com fornecedores para que eles possam enviar suas propostas.
                </DialogContentText>


                <FormControlLabel control={<Checkbox defaultChecked />} label="Compartilhar com fornecedores cadastrados." />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.share} variant="outlined" fullWidth >Compartilhar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareQuote;