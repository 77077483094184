import { ReceiptLongTwoTone, ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import { Badge, Fab, Zoom } from '@mui/material';
import './shake.css';
import React from 'react';

const CartFab = (props) => {
    const [animate, setAnimate] = React.useState(false);

    React.useEffect(() => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 1500)
    }, [props.items]);

    return (
        <Zoom in={true}>
            <Fab sx={{ position: "fixed", bottom: 15 }} style={{left: `calc(50% - 30px)`}} color="primary" className={animate ? 'shake' : ''} onClick={props.onClick}>
                <Badge showZero={false} badgeContent={props.items ?? 0} color="secondary" anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                       <img src='gettaquote.gif' width={40} height={40}/>
                </Badge>
            </Fab>
        </Zoom>
    );
};

export default CartFab;