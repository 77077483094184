import api from "../../API/api";

function quote2QuoteList(quote){
    return {
        _id: quote._id,
        reference: quote._id.substring(quote._id.length-4).toUpperCase() ,
        address: quote.address.reference,
        subTitle: quote.items.map(i => i.product.product).join(", "),
        status: quote.status,
        created_at: quote.created_at
    }
  }
  
const QuoteService = {
    getQuotes: () => {
        return new Promise((resolve,reject) => {
            const p = api.get(`/v1/quotes`);
            p.then((response) => {
                const quoteList = response.data.map((q) => quote2QuoteList(q) );
                resolve(quoteList)
            });
                
        });
    },
}

export default QuoteService;