import { Button, CircularProgress, Container, Grid, List, Typography } from '@mui/material';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ProductListItem from './Components/ProductListItem';
import ItemSkeleton from '../NewQuote/Components/ItemSkeleton';
import ProductsService from '../NewQuote/Services/productsService';

function ProductSearch(props) {
    const [hasMore, setHasMore] = useState(true);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function loadItems(loadMore) {
        const opts = loadMore ? options : [];
        if (props.search.length < 3 && props.barCode == null) {
            setOptions([]);
            return;
        }
        setIsLoading(true);
        const promise = ProductsService.getProducts(props.search, opts.length,props.barCode);
        promise.then((response) => {
            setOptions([...opts, ...response]);
            setHasMore(response.length > 0);
        })
        promise.catch(() => setHasMore(false));
        promise.finally(() => setIsLoading(false));
    }

    const gridContainer = {
        gridAutoRows: "1fr" 
      };

    React.useEffect(() => { loadItems(false) }, [props.search]);
    React.useEffect(() => { if(props.barCode)
                                loadItems(false) }, [props.barCode]);

    return (
        <Container sx={{ mt: 2, overflow: "auto", paddingBottom: "70px" }}>
            {(props.search.length < 3 && props.barCode == null )? <Typography> Use a Barra de busca para encontrar o produto desejado</Typography> :

                <Grid spacing={1} container sx={gridContainer}>
                    {isLoading ?
                        new Array(10).fill(1).map((a, i) => <ItemSkeleton key={i} />) : options.map((e) =>
                            <Grid item xs={12} lg={4} md={6}>
                            <ProductListItem
                                key={e.id}
                                id={e.id}
                                product={e?.product}
                                picture={e?.picture}
                                brand={e?.brand}
                                productAdd={() => props.productAdd(e.id)}
                                onClick={() => { props.productOpen(e.id) }} />
                                </Grid>)
                    }
                </Grid>
            }
                                
                                <Button fullWidth variant='outlined' sx={{mt:2}} onClick={() => loadItems(true)} disabled={!hasMore}>Carregar Mais</Button>
                    <Button fullWidth variant='outlined' sx={{mt:1,mb:2}} onClick={() => alert("Funcionalidade em Desenvolvimento")} disabled={!hasMore}>Não Encontrei o Produto</Button>
        </Container>
    );
};

export default ProductSearch;