import React from 'react';
import ProductDetail from './Components/ProductDetail';
import { useNavigate } from 'react-router-dom';
import BottomBarQuote from '../Components/BottomBarQuote';
import SearchAppBar from '../Layout/SearchAppBar';
import CartService from './Services/cartService';
import ProductSearch from '../Product/ProductSearch';
import { Button, debounce, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import QuantityDialog from './Components/QuantityDialog';
import QRCodeScanner from './QRCodeScanner';
import { Close } from '@mui/icons-material';

const NewQuote = (props) => {
    const [search, setSearch] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [activeProduct, setActiveProduct] = React.useState(null);
    const [cartEvent, setCartEvent] = React.useState(false);
    const [cart, setCart] = React.useState({});
    const [openBarCodeScanner, setOpenBarCodeScanner] = React.useState(false);
    const nav = useNavigate();
    const [quantityDialogOpen, setQuantityDialogOpen] = React.useState(false);
    const [barCode,setBarCode] = React.useState(null);


    function goToCart() {
        nav("/cart");
    }

    function addToCart(productId, quantity) {
        const promise = CartService.addToCart(productId, quantity);
        promise.then(() => {
            setQuantityDialogOpen(false);
            setCartEvent(!cartEvent)
        });
    }

    React.useEffect(() => {
        const promise = CartService.getCart();
        promise.then((response) => { setCart(response.data) });
    }, [cartEvent]);


    const debounceSearch = debounce((e) => {
        setBarCode(null);
        if (e.length > 2) setSearch(e);
        else setSearch("");
    }, 500);

    function searchBarCode(e){
        setBarCode("7896880016568"); 
        setOpenBarCodeScanner(false);
    }

    return (
        <>
            <SearchAppBar title="Montar Coleta" onChange={debounceSearch} toggleMenu={props.toggleMenu} openScanner={() => setOpenBarCodeScanner(true)}>
                <ProductSearch search={search} barCode={barCode} productAdd={id => setQuantityDialogOpen(id)} productOpen={(id) => setActiveProduct(id)} />
            </SearchAppBar>

            <QuantityDialog productId={quantityDialogOpen} open={quantityDialogOpen} handleClose={() => setQuantityDialogOpen(null)} onChange={addToCart} />

            <ProductDetail openDialog={activeProduct} closeDialog={() => setActiveProduct(null)} productId={activeProduct} quantity={cart[activeProduct]} />
            <BottomBarQuote cart={cart} goToCart={goToCart} />


            <Dialog open={openBarCodeScanner}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Escanear Código
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenBarCodeScanner(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close />
                </IconButton>
                < QRCodeScanner detect={searchBarCode} />
            </Dialog>
        </>

    );
};

export default NewQuote;