import { Dialog,Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

export default function TwoActionsDialog(props) {
    const { open, title, message, action1, action2,button1Text,button2Text, onClose } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={action1}>{button1Text}</Button>
                {action2 &&<Button onClick={action2} autoFocus>{button2Text}</Button>}
            </DialogActions>
        </Dialog>
    );
}