import api from '../../API/api';

const ProductsService = {

    getProducts: (search,skip,barCode) => {
        const params = barCode ? {barCode} : {search,skip};
        return new Promise((resolve, reject) => {
            api.get(`/products`,{params}).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },

    getProduct: (productId) => {
        return new Promise((resolve, reject) => {
            api.get(`/product/${productId}`).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },
}
export default ProductsService;