import { BookmarkTwoTone, BookmarkBorderTwoTone } from '@mui/icons-material';
import { Box,Button, ListItem, IconButton, ListItemAvatar, Avatar, ListItemText, Typography, Chip, Divider } from '@mui/material';
import React from 'react';
import { ProductImage } from '../../Components/ProductImage';

const QuoteProductListItem = (props) => {
    const e = props.product;
    const p = props.product.product;
    return (
        <Box>
            <ListItem secondaryAction={
                <IconButton edge="end" aria-label="delete">
                    {e.hasSelectedReply ? <BookmarkTwoTone color="primary" /> : <BookmarkBorderTwoTone />}</IconButton>
            }>
                <ListItemAvatar>
                    <Avatar color="success" >
                        <ProductImage src={p.picture} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<Typography>{p.product}</Typography>} secondary={p.brand} />
            </ListItem>
            <Box sx={{ m: 2, flexGrow: 1, display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <Box sx={{ flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 1 }}>
                    {e.isBestPriceSelected && <Chip label="Melhor Preço" variant="outlined" color="success" size="small" sx={{ alignSelf: "center" }} />}
                    {e.bestQuote && <Chip label="Melhor Coleta" variant="outlined" color="secondary" size="small" sx={{ alignSelf: "center" }} />}
                    {e.bestSuplyer && <Chip label="Melhor Fornecedor" variant="outlined" color="warning" size="small" sx={{ alignSelf: "center" }} />}
                </Box>
            </Box>
            <Box sx={{ m: 2, flexGrow: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography variant='h6' color="textSecondary"> {e.price ? "R$ " + (1 * e.price).toFixed(2) : ""}</Typography>
                <Button variant="outlined" size="small" sx={{flexShrink: 0 }} onClick={() => props.openProductDialog(p)}>Ver Respostas  </Button>
            </Box>
            <Divider component="li" />
        </Box>
    );
};

export default QuoteProductListItem;