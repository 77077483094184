import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { createTheme,Mui, ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  typography:{
    h6:{
      color:"white"
    }
  },
  palette: {
    primary: {
      main: '#FE6700'
    }
  }
});
root.render(
  // <React.StrictMode>
    <HashRouter>

    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </HashRouter>
   
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
