import { AutoAwesome, IosShare, ReceiptLong, Share } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction,Badge, IconButton } from '@mui/material';
import React from 'react';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Products from './Products';
import {  useParams } from 'react-router-dom';
import AppBarBack from '../Layout/AppBarBack';
const Quote = (props) => {
    const id = useParams().id;

    function share(e){
        e.preventDefault();
        const data = {
            title:"Enviar coleta de preços",
            text: "Você recebeu uma coleta de preços, acesse o link abaixo para respoder.",
            url:`https://coletefy.com/#/reply/${id}`
        }
        navigator.share(data);
    }
    
    return (
        <AppBarBack title={`#${id.substring(id.length-4)}`} secondaryAction={<IconButton onClick={share} sx={{color:"white"}}><IosShare/></IconButton>}>
           <Products/>
            <div style={{ position: 'fixed', bottom: 0, height: "100px", width: "100%" }}>
                <div style={{ height: "45px", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,1))" }}></div>
                <BottomNavigation
                    showLabels
                    value={1}
                    onChange={(event, newValue) => { }}
                >
                    <BottomNavigationAction label="Pedidos" icon={<Badge badgeContent={3} color="primary"><ReceiptLong /></Badge>} disabled />
                    <BottomNavigationAction label="Produtos" icon={<CardGiftcardIcon />} disabled />
                    <BottomNavigationAction label="Sugeridos" icon={<AutoAwesome />} />
                </BottomNavigation>
            </div>
        </AppBarBack>
    );
};

export default Quote;