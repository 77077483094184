import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';

export default function AppBarBack(props) {
  // const [open,setOpen] = useOutletContext();
  const nav = useNavigate();
  const goBack = () => {
    if(props.goBack) props.goBack();
    else
    nav(-1);
  }
  return (
    <>
    <AppBar position="sticky" sx={{boxShadow: props.extended ? 0 : 3}}>
      <Toolbar> <IconButton onClick={goBack}><ChevronLeft sx={{color:"white"}} /></IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
          {props.title}
        </Typography>
        {props.secondaryAction}
      </Toolbar>
    </AppBar>

    {props.children}
    </>
  );
}