import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';
import ProductsService from '../Services/productsService';

export default function QuantityDialog(props) {
  const [quantity, setQuantity] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  React.useEffect(() => {
    if (props.productId)
      ProductsService.getProduct(props.productId).then(p => {
        setProduct(p)
      });
  }, [props.productId]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle sx={{"color":"black"}}>Quantidade</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
        Indique a quantidade de <strong>{product?.produto} </strong> que você deseja adicionar ao carrinho.</Typography>
        <TextField
          value={props.name}
          sx={{ mt: 2 }}
          required
          type="number"
          id="quantity"
          label="Quantidade"
          inputProps={{inputMode:"numeric"}}
          onChange={e => setQuantity(e.target.value)}
          fullWidth
        />
        <TextField fullWidth 
          label="Observação" 
          id="obs"/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' disabled={!quantity} onClick={() => props.onChange(props.open, quantity)} endIcon={<Add />}>Adicionar</Button>
      </DialogActions>
    </Dialog>
  );
}