import React from 'react';
import { ListItem, ListItemText, Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ChevronRight, Circle, HourglassBottomTwoTone, Reply } from '@mui/icons-material';
import moment from 'moment';

const ReplyListItem = (props) => {
    const sx={fontSize:"15px"}
    function renderStatus() {
        switch (props.status) {
            case "expired":
                return <HourglassBottomTwoTone sx={{...sx,color:'red'}}/>
            case "viewed":
                return <Circle sx={{...sx,color:"green"}}/>
            case "sent":
                return <Reply sx={{...sx,color:'lightgrey'}}/>
            default:
                return <Circle sx={{...sx,color:'lightgrey'}}/>
        }
    }

    function renderDate(){
        if(moment(props.created_at).isAfter(moment().subtract(1,'day'))) return moment(props.created_at).fromNow();
        return moment(props.created_at).format('DD/MM/YYYY');
    }
    return (
        <div>
            <ListItem onClick={() => props.onClick()}>
                <ListItemText primary={<Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{flexGrow:0,marginRight:"10px"}}>{renderStatus() }</div>
                    <Typography sx={{flexGrow:1}}>{props.title}</Typography>
                    <Typography variant='caption' sx={{flexGrow:0}}>{renderDate()}</Typography> 
                    <ChevronRight sx={{fontSize:"18px",flexGrow:0}}/> </Box>} 
                    secondary={props.subtitle} secondaryTypographyProps={{ noWrap: true }} />
            </ListItem>
            <Divider component="li" />
        </div >
    );
};

export default ReplyListItem;