import * as React from 'react';
import { TextField, Stack, Button, Card, CardMedia, CardContent, Typography, CardActions, CardHeader, Chip, IconButton, Snackbar, Slide, CircularProgress, Skeleton, Dialog, debounce, AppBar, Toolbar } from "@mui/material";
import { CurrencyInput } from "react-currency-mask";
import { Check, CheckCircle, ChevronLeft, Close, ErrorOutline, MoreVert } from '@mui/icons-material';
import ReplyItemService from './Services/ReplyItemService';
import { ProductImage } from '../Components/ProductImage';
import ProductSearch from '../Product/ProductSearch';

export default function Step2(props) {
    const [price, setPrice] = React.useState(props.item.price);
    const [observation, setObservation] = React.useState(props.item.observation);
    const [priceError, setPriceError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openProductSelect, setOpenProductSelect] = React.useState(false);
    const [showSnackbarSuccess, setShowSnackbarSuccess] = React.useState(false);
    const [showSnackbarError, setShowSnackbarError] = React.useState(false);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [search, setSearch] = React.useState("");
    let lockSearch = false;

    const updatePrice = (event, originalValue, maskedValue) => {
        setPrice(originalValue);
        setPriceError(false);
    }

    const debounceSearch = debounce((e) => {
        lockSearch = true;
        if (e.length > 2) setSearch(e);
        else setSearch("");
    }, 500);


    function updateItem(replyItem = true) {
        const p = price ?? 1;
        if (!price && replyItem)
            setPriceError(true);
        else {
            setLoading(true);
            const data = { price: p, observation, replyItem, replyProduct: props.item.product._id };
            console.log(data);
            ReplyItemService.putReplyItem(props.quoteId, props.item._id, data)
                .then((item) => {
                    props.onChange(item);
                    setShowSnackbarSuccess(true);
                }).
                catch((error) => {
                    setShowSnackbarError(true);
                    setLoading(false);
                });
        }
    }

    return (<div>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showSnackbarSuccess}
            onClose={() => { props.nextStep() }}
            TransitionComponent={Slide}
            autoHideDuration={1200}
            message="Item salvo com sucesso"
            action={<IconButton><CheckCircle color="success" /></IconButton>}
        />

        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showSnackbarError}
            onClose={() => { setShowSnackbarSuccess(false) }}
            TransitionComponent={Slide}
            autoHideDuration={1200}
            color='error'
            message="Erro ao salvar item"
            action={<IconButton><ErrorOutline color="error" /></IconButton>}
        />
        <Card >
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVert />
                    </IconButton>
                }
                subheader={<Chip label={props.item.product.department} size="small" />} />
            <CardMedia
                sx={{ objectFit: "contain" }}
                title={props.item.product.product} >
                <div style={{ height: "140px", width: "100%" }}>
                    <ProductImage src={props.item.product.picture} width="100%" height="100%" style={{ objectFit: "contain" }} onLoad={() => setImageLoaded(true)} />
                </div>
            </CardMedia>
            <CardContent>
                <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <Button color='warning' sx={{ alignSelf: "center", flexGrow: 0 }} variant="outlined">{props.item.quantity} x</Button>
                        <Typography variant="body" sx={{ color: 'text.secondary', flexGrow: 1 }}>
                            {props.item.product.product}
                        </Typography>
                    </Stack>

                    {/* <Button fullWidth variant='outlined' disabled={loading} onClick={() => setOpenProductSelect(true)}> Oferecer Produto Alternativo</Button> */}
                    <CurrencyInput value={price}
                        disabled={loading}
                        onChangeValue={updatePrice}
                        InputElement={<TextField label="Valor Unitário" size="small" required
                            helperText={priceError ? "Este campo é obrigatório" : ""} error={priceError} />}
                    />

                    <TextField multiline
                        rows={4}
                        label="Observações"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        disabled={loading} />
                </Stack>
            </CardContent>
            <CardActions>
                <Button fullWidth size="small" color="error" variant='contained' disabled={loading} onClick={() => updateItem(false)}>Não Cotar</Button>
                <Button fullWidth size="small" color="primary" variant='contained' onClick={() => updateItem()} disabled={loading}>Salvar</Button>
            </CardActions>
        </Card>
        <Dialog open={openProductSelect} fullScreen>
            <AppBar position="sticky" sx={{ boxShadow: props.extended ? 0 : 3 }}>
                <Toolbar> 
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                        Buscar Produts
                    </Typography>
                    <IconButton color="inherit" onClick={e => setOpenProductSelect(false)}><Close /></IconButton>
                    {props.secondaryAction}
                </Toolbar>
                <Toolbar>

            <TextField size="small" fullWidth onChange={(e) => debounceSearch(e.target.value)} disabled={lockSearch} sx={{ml:2,mr:2,backgroundColor:"white",borderRadius:"10px"}}/>
                </Toolbar>
            </AppBar>
            <ProductSearch search={search} productAdd={() => { }} productOpen={(id) => { }} />
        </Dialog>
    </div>);
}