import api from "../../API/api";

const ProfileService = {

    getProfile: (quote) => {
        return new Promise((resolve, reject) => {
            api.get(`/v1/profile`,
                quote).then((response) => {
                    if (response.status != 200) throw new Error("Error");
                    resolve(response.data);
                }).catch((error) => { reject(error) });
        });
    },


    /**
     * 
     * @param {*} name 
     * @param {*} email 
     * @param {*} company 
     * @param {*} profilePicture 
     * @returns 
     */
    postProfile: (name,email,company,profilePicture) => {
        return new Promise((resolve, reject) => {
            api.post(`/v1/profile`,{name,email,company,profilePicture}).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },

    putPicture: (picture) => {
        let data = new FormData();
        data.append('file', picture);
        return new Promise((resolve, reject) => {
            api.put(`/v1/profile/picture`,data).then((response) => {
                    if (response.status != 200) throw new Error("Error");
                    resolve(response.data);
                }).catch((error) => { reject(error) });
        });
    }
}

export default ProfileService;