import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Container } from '@mui/material';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import ReplyService from './Services/ReplyServices';
import { useParams } from 'react-router-dom';
import AppBarMenu from '../Layout/AppBarMenu';
import Step4 from './Step4';

export default function ReplyStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState([<></>]);
    const [reply, setReply] = React.useState(null);
    const replyId = useParams().id;
    const [maxSteps, setMaxSteps] = React.useState(0);
    const [items, setItems] = React.useState(new Map());
    const [loading,setLoading] = React.useState(true)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        const p = ReplyService.getReply(replyId);
        p.then((reply) => {
            setReply(reply);
            const items = new Map(reply.items.map((i) => [i._id,i]));
            setItems(items);
            setMaxSteps(reply.items.length + 3);
            setLoading(false);
            if(reply.status != "new"){
                setActiveStep(reply.items.length + 2)
            }
        });
    }, []);

    function updateItem(item) {
        items.set(item._id, item);
        setItems(new Map(items));
    }
    React.useEffect(() => {
        const productSteps = Array.from(items.values()).map((item) => <Step2 key={item._id} quoteId={reply.replyTo} item={item} onChange={updateItem} nextStep={handleNext}/>);
        const step1 = reply == null ? <></> : <Step1 maxDeliveryDate={reply.maxDeliveryDate} reply={reply} nextStep={handleNext}/>;
        const step3 = <Step3 nextStep={handleNext} onChange={setReply} repliedBy={reply?.repliedBy} quoteId = {reply?.replyTo} company={reply?.company} observation={reply?.observation} profilePicture={reply?.profilePicture}/>;
        const ste = [step1,...productSteps,step3, <Step4 reply={reply}/>];
        setSteps(ste);
    },[items,reply])

    return (
        <>
            <AppBarMenu title={`#${replyId.substring(replyId.length - 6).toUpperCase()}`} />
            {
                loading ? "carregando":             
                <><MobileStepper
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={reply.status == "new" || activeStep == maxSteps-1}
                    >
                        Próximo
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0 || reply.status != "open"}>
                        <KeyboardArrowLeft />
                        Voltar
                    </Button>
                }
            />
            <Container>
                {steps[activeStep]}</Container></>
            }

        </>

    );
}