import React from "react";
import ButtonAppBar from "./Layout/AppBarBack";
import { AppBar, Avatar, Badge, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import { ChevronLeft, ExitToApp, ExitToAppOutlined, LocationCity, Settings, ShoppingCart } from "@mui/icons-material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Outlet, useNavigate } from "react-router-dom";
import SearchAppBar from "./Layout/SearchAppBar";
import ProfileService from "./Settings/Services/ProfileService";


export default (props) => {
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState();
    const [profilePicture, setProfilePicture] = React.useState();
    const [profileDialogOpen,setProfileDialogOpen] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        ProfileService.getProfile().then((p) => {
            if (!p.name) {
                setProfileDialogOpen(true)
            }
            setUserName(p.name);
            setProfilePicture(p.profilePicture);
        })
    }, [])

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const openQuotes = () => {
        navigate("/quotes");
        setOpen(false);
    }

    const openQuote = () => {
        navigate("/quote");
        setOpen(false);
    }

    const openSites = () => {
        navigate("/addresses");
        setOpen(false);
    }

    const openSettings = () => {
        navigate("/settings");
        setOpen(false);
    }

    const openPersonalDetails = () => {
        navigate("/settings/personalDetails");
        setOpen(false);
        setProfileDialogOpen(false);
    }

    const openReplies = () => {
        navigate("/replies");
        setOpen(false);
    }

    const logout = () =>{
        localStorage.clear();
        navigate("/welcome")
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation">
            <Toolbar color="primary">
                <Avatar src={profilePicture} />
                <Typography variant="body" component="div" sx={{ flexGrow: 1, textAlign: "left", ml: 2 }}>{userName ?? "Usuário"}</Typography>
                <IconButton onClick={() => toggleDrawer()} edge='end'>
                    <ChevronLeft />
                </IconButton>
            </Toolbar>
            <Divider />
            <List subheader={<ListSubheader>Comprador</ListSubheader>}>
                <ListItem key="Nova Coleta" disablePadding>
                    <ListItemButton onClick={() => openQuote()}>
                        <ListItemIcon>
                            <Badge badgeContent={0} color="primary">
                                <ShoppingCart />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={"Nova Coleta"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Coletas" disablePadding>
                    <ListItemButton onClick={() => openQuotes()}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Coletas"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key="Obras" disablePadding>
                    <ListItemButton onClick={openSites}>
                        <ListItemIcon>
                            <LocationCity />
                        </ListItemIcon>
                        <ListItemText primary={"Minhas Obras"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />

            <List subheader={<ListSubheader>Fornecedor</ListSubheader>}>
                <ListItem key="Coletas" disablePadding>
                    <ListItemButton onClick={openReplies}>
                        <ListItemIcon >
                            <Badge badgeContent={0} color="primary">
                                <MailIcon color="action" />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={"Coletas"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Pedidos" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LocalShippingIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Pedidos"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key="Preferencias" disablePadding>
                    <ListItemButton onClick={openSettings}>
                        <ListItemIcon>
                            <Settings color="action" />
                        </ListItemIcon>
                        <ListItemText primary={"Preferencias"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Logout" disablePadding>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <ExitToAppOutlined color="action" />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            {/* {renderBar()} */}
            <Drawer open={open} onClose={() => toggleDrawer()}>
                {DrawerList}
            </Drawer>
            <Outlet context={[open, setOpen]} />
            <Dialog open={profileDialogOpen}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    Para aumentar a qualidade das suas coletas, finilize o seu cadastro. 
                </DialogContent>
                <DialogActions>
                    <Button onClick={openPersonalDetails} variant="outlined">Preencher</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}