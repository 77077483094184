import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import Divider from '@mui/material/Divider';

const ReplyListSkeleton = (props) => {
    return (
        <div>
            <ListItem key={props.key} >
                    <Skeleton animation='wave' variant='circular' sx={{width:"40px",height:"40px",mr:2}}/>
                <ListItemText primary={<Skeleton animation='wave' variant='text'/>} 
                    secondary={<Skeleton animation='wave' variant='text' width="50%"/>} />
            </ListItem>
            <Divider component="li" />
        </div >
    );
};

export default ReplyListSkeleton;