import api from "../../API/api";

const AddressService = {

    getAddresses: () => {
        return new Promise((resolve, reject) => {
            api.get(`/v1/addresses`).then((response) => {
                resolve(response.data);
            }).catch((error) => { console.log(error) });
        });
    },

    createAddress:(address) => {
        return new Promise((resolve, reject) => {
            api.post(`/v1/address`,address).then((response) => {
                resolve(response.data);
            }).catch((error) => { console.log(error) });
        });
    },

    fetchCep:(cep) => {
        return new Promise((resolve, reject) => {
            api.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
                resolve(response.data);
            }).catch((error) => { console.log(error) });
        });
    }
}

export default AddressService;