import axios from 'axios';
const coletaService = process.env.REACT_APP_COLETA_SERVICE;

const api = axios.create({
  baseURL: coletaService,
  timeout: 6000
});

api.interceptors.request.use((config) => {
  if (localStorage.getItem("token"))
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  return config;
});

api.interceptors.response.use(undefined, (error) => {
  switch (error.response.status) {
    case 401: {
      localStorage.removeItem("token");
      break;
    }
    case 400:{
      return Promise.reject(error.response.data);
    }
  }
});

export default api;