import { CardContent, Typography, Card, Button, useTheme } from '@mui/material';
import React from 'react';


/**
 * @param {string} nome
 */
const SiteCard = (props) => {
    const theme = useTheme();
    const baseClass = { m: 2,height:"120px", width: "200px", boxShadow: "5", borderRadius: "15px" };
    const selectedClass = { ...baseClass , border: "solid", borderWidth: "2px", borderColor: `${theme.palette.primary.main}` };
    const notSelectedClass = { ...baseClass,m: 2, width: "200px", boxShadow: "5", borderRadius: "15px" };

    return (
        <Card sx={props.selected ? selectedClass : notSelectedClass} onClick={props.onClick}>
            <CardContent>
                <Typography noWrap variant="h5" component="div" color={"primary"}>
                    {props.obra}
                </Typography>
                <Typography noWrap color="text.secondary">
                    {props.municipio}, {props.uf}
                </Typography>
                <Typography noWrap variant="body2">
                    {props.logradouro}
                </Typography>
                <Typography noWrap variant="body2">
                    nº {props.numero}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default SiteCard;