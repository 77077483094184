import { Add, Close, Delete } from '@mui/icons-material';
import { Dialog, Box, AppBar, Toolbar, Typography, IconButton, CardMedia, CardActions, TextField, CardContent, Container, Stack, Card, CardHeader, Button, Chip } from '@mui/material';
import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QuantityInput from './NumberInput';
import { ProductImage } from '../../Components/ProductImage';

const ProductDetail = (props) => {
    const [quantity, setQuantity] = React.useState(props.quantity);
    const [activeProduct, setActiveProduct] = React.useState(null);
    const coletaService = process.env.REACT_APP_COLETA_SERVICE;

    useEffect(() => { setQuantity(props.quantity) }, [props.quantity]);

    useEffect(() => {
        if(!props.productId) return
        axios.get(`${coletaService}/product/${props.productId}`).then((response) => {
            setActiveProduct(response.data);
        }).catch((error) => { console.log(error) });
    }, [props.productId]);


    function removeFromCart() {
        const token = localStorage.getItem("token");
        const data = { product: activeProduct.id, quantity: 0 };
        axios.put(`${coletaService}/cart`, data, { headers: { "Authorization": `Berear ${token}` } }).then((response) => {
            props.closeDialog();
        }).catch((error) => {
            console.log(error)
        });
    }


    return (
        <Dialog open={props.openDialog} fullScreen>

            <Box sx={{ width: "100%" }}>
                <AppBar sx={{ position: 'static' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" align='center'>
                            Produto
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.closeDialog}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container sx={{ mt: 2 }}>
                    <Stack spacing={2} m={2} p={0}>
                        <Card>
                            <CardHeader
                                title={activeProduct?.marca}
                                subheader={activeProduct?.produto} />
                            <CardMedia sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <ProductImage src={activeProduct?.foto_webp}/>
                            </CardMedia>
                            <CardContent>
                                <Stack>
                                {<Chip label={activeProduct?.departamento} color="warning"/>}
                                {parse(activeProduct?.descricao ?? "")}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Container>
            </Box>
        </Dialog>
    );
};

export default ProductDetail;