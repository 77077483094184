import { CheckCircle, ErrorOutline, PhotoTwoTone } from "@mui/icons-material";
import { AppBar, Avatar, IconButton, Slide, Snackbar, styled, TextField, Typography } from "@mui/material";
import { Stack, Button } from "@mui/material";
import React from "react";
import UploadService from "../Services/UploadService";
import AppBarBack from "../Layout/AppBarBack";
import ProfileService from "./Services/ProfileService";

export default function PersonalDetails(props) {
    const [loading, setLoading] = React.useState(false);
    const [ name,setName] = React.useState();
    const [company,setCompany] = React.useState();
    const [email,setEmail] = React.useState();
    const [profilePicture,setProfilePicture] = React.useState(props.profilePicture);
    const [showSnackbarSuccess, setShowSnackbarSuccess] = React.useState(false);
    const [showSnackbarError, setShowSnackbarError] = React.useState(false);

    function updateImage(event) {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            UploadService.uploadFile(file).then((r) => {
                setProfilePicture(r);
            });
        };
        reader.readAsDataURL(file);

    }

    function updateUser(){
        const  p = ProfileService.postProfile(name,email,company,profilePicture);
        p.then((r) => {
            setLoading(true);
            setShowSnackbarSuccess(true);
        })
    }

    React.useEffect(() =>{
        ProfileService.getProfile().then((p) => {
            setCompany(p.company);
            setEmail(p.email);
            setName(p.name);
            setProfilePicture(p.profilePicture);
        })
    },[])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    return (
        <div>
            <AppBarBack title="Informações Pessoais"></AppBarBack>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showSnackbarSuccess}
                onClose={() => {setLoading(false);setShowSnackbarSuccess(false)}}
                TransitionComponent={Slide}
                autoHideDuration={1200}
                message="Item salvo com sucesso"
                action={<IconButton><CheckCircle color="success" /></IconButton>}
            />

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showSnackbarError}
                onClose={() => { setShowSnackbarSuccess(false) }}
                TransitionComponent={Slide}
                autoHideDuration={1200}
                color='error'
                message="Erro ao salvar item"
                action={<IconButton><ErrorOutline color="error" /></IconButton>}
            />
            <div style={{ overflow: 'auto', height: "100%", paddingBottom: "100px" }}>
                <Stack m={2} spacing={2} >
                    <Typography variant="h6" sx={{alignSelf:"center"}}>Sua Logo</Typography>
                    <Button
                        disabled={loading}
                        component="label"
                        role={undefined}
                        tabIndex={-1}>
                        <Avatar sx={{ width: 80, height: 80, margin: "auto" }} alt="Remy Sharp" >
                            {profilePicture ? <img src={profilePicture} style={{ objectFit: "contain", height: "100%" }} /> : <PhotoTwoTone sx={{ height: "60px", width: "60px" }} />}
                        </Avatar>

                        <VisuallyHiddenInput
                            type="file"
                            onChange={updateImage}
                            multiple
                        />
                    </Button>
                    <TextField label="Seu Nome" name="name" value={name} required onChange={(e) => setName(e.target.value)} disabled={loading} InputLabelProps={{ shrink: name }}/>
                    <TextField label="Nome da Empresa" name="company" value={company} required onChange={(e) => setCompany(e.target.value)} disabled={loading} InputLabelProps={{ shrink: company }}/>
                    <TextField label="Seu Email" name="email" value={email} required onChange={(e) => setEmail(e.target.value)} disabled={loading} InputLabelProps={{ shrink: email }}/>
                    <Button fullWidth variant="outlined" onClick={updateUser} disabled={loading || !name}> Salvar</Button>
                </Stack>
            </div>
        </div>
    );
}