import api from "../../API/api";

const QuoteService = {

    postQuote: (quote) => {
        return new Promise((resolve, reject) => {
            api.post(`/v1/quote`, 
                quote).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },


    getQuote: (quoteId) => {
        return new Promise((resolve, reject) => {
            api.get(`/v1/quote/${quoteId}`).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },

    selectReplyItem: (quoteId,itemId,replyId) => {
        const data = replyId ? {replyId}:null;
        return new Promise((resolve, reject) => {
            api.put(`/v1/quote/${quoteId}/item/${itemId}`, 
                data).then((response) => {
                if (response.status != 200) throw new Error("Error");
                resolve(response.data);
            }).catch((error) => { reject(error) });
        });
    },

    getQuoteItem: (quoteId,itemId) => {
        return new Promise((resolve, reject) => {
            api.get(`/v1/quote/${quoteId}`).then((response) => {
                if (response.status != 200) throw new Error("Error");
                const item = response.data.items.find((i) => i._id === itemId);
                resolve(item);
            }).catch((error) => { reject(error) });
        }); 
    }
}

export default QuoteService;