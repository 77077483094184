import { ListItem, IconButton, ListItemAvatar, Avatar, ListItemText, Divider, Box, debounce, Button } from '@mui/material';
import React from 'react';
import QuantityInput from './NumberInput';
import { ProductImage } from '../../Components/ProductImage';

const ProductListItem = (props) => {
    const [quantity, setQuantity] = React.useState(props.quantity); 
    const updateQuantity = debounce((quantity)=> props.setQuantity(quantity),500);
    
    React.useEffect(() => {
        updateQuantity(quantity);
    }, [quantity]);

    return (
        <>
        <Box sx={{m:2}}>
            <Box sx={{ display: "flex" }}>
                <Box sx={{alignContent:"center"}}><Avatar><ProductImage src={props.picture}/></Avatar></Box>
                <ListItemText primary={props.product} secondary={props.brand} sx={{ml:2}} />
            </Box>
            <Box sx={{display:"flex",fledDirection:"column",justifyContent:"flex-end",gap:"10px"}}>
                <Button onClick={() => setQuantity(0)}>Remover</Button>
                <QuantityInput value={quantity} onChange={setQuantity}/>
            </Box>
        </Box>
        <Divider variant="fullWidth" component="li" />
        </>
    );
};

export default ProductListItem;