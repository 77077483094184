import React from 'react';
import { ListItem, ListItemText, Box, Typography, Chip } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ChevronRight, Circle, HourglassBottomTwoTone, Reply } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br');
const ReplyListItem = (props) => {
    const sx={fontSize:"15px"}
    function renderStatus() {
        switch (props.status) {
            case "expired":
                return <HourglassBottomTwoTone sx={{...sx,color:'red'}}/>
            case "viewed":
                return <Circle sx={{...sx,color:"green"}}/>
            case "sent":
                return <Reply sx={{...sx,color:'lightgrey'}}/>
            default:
                return <Circle sx={{...sx,color:'lightgrey'}}/>
        }
    }
    
    function renderDate(){
        if(moment(props.created_at).isAfter(moment().subtract(1,'day'))) return moment(props.created_at).fromNow();
        return moment(props.created_at).format('DD/MM/YYYY');
    }
    
    return (
        <div>
            <ListItem onClick={() => props.onClick()}>
                <ListItemText primary={<Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{flexGrow:0,marginRight:"10px"}}>{renderStatus() }</div>
                    <div style={{flexGrow:1,marginRight:"10px",display:"flex",flexDirection:"row"}}><Typography sx={{flexGrow:0}}>{props.reference}</Typography><Typography sx={{flexGrow:0,ml:1,alignContent:"center"}} variant='body2'>- {props.address}</Typography></div>
                    <Typography variant='caption' sx={{flexGrow:0}}>{renderDate()}</Typography> 
                    <ChevronRight sx={{fontSize:"18px",flexGrow:0}}/> </Box>} 
                    secondary={props.subtitle} secondaryTypographyProps={{ noWrap: true }} />
            </ListItem>
            <Divider component="li" />
        </div >
    );
};

export default ReplyListItem;