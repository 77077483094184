import { Add, Delete, Remove } from '@mui/icons-material';
import { Button, debounce, IconButton, TextField } from '@mui/material';
import * as React from 'react';


export default function QuantityInput(props) {
  const [value,setValue] = React.useState(1*props.value);

  function add(){
    setValue(1*value+1);
  }
  function remove(){
    setValue(value-1);
  }

  React.useEffect(() => {
    props.onChange(value);
  },[value]);

  return <div>
    <IconButton sx={{mr:1,backgroundColor:"lightgray"}} onClick={remove}>
    {value != 1 ? <Remove/> : <Delete/> }</IconButton>
    <TextField size='small'
      value={value}
      onChange={e => setValue(e.target.value)}
      inputProps={{ min: 0, style: { textAlign: 'center' },inputMode:"numeric" }}
      type='number'
      sx={{ width: "80px" }} />
      <IconButton sx={{ml:1,backgroundColor:"lightgray"}} onClick={add}><Add /></IconButton>
  </div>
}

