import React from 'react';
import { Avatar, Dialog, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListSubheader, Slide, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, ChevronRight } from '@mui/icons-material';
import axios from 'axios';
import SiteListItem from './Components/SiteListItem';
import AppBarMenu from '../Layout/AppBarMenu';
import AddressService from './Services/AddressService';
import AddressListSkeleton from './Components/AddressListSkeleton';

  
const Addresses = () => {
    const nav = useNavigate();
    const [sites, setSites] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(true);

    React.useEffect(() => {
        const promise = AddressService.getAddresses();
        promise.then((a) => {
            setSites(a);
            setIsLoading(false);});
    }, []);

    function goToNewAddress(){
        nav("/address/new")
    }

    return (
        <AppBarMenu title="Obras">
            <List sx={{ width: '100%' }} component="nav" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Obras
                </ListSubheader>
            } >
                {
                isLoading?
                [1,1,1,1,1].map((e,i) => <AddressListSkeleton key={i}/>):
                sites.map((e, idx) => <SiteListItem site={e}/>)}
            </List>
            <Fab sx={{position:"fixed",bottom:"20px",right:"20px"}} color="primary" onClick={() => goToNewAddress()}>
                <Add />
            </Fab>
       </AppBarMenu>
    );
};

export default Addresses;