import React from 'react';
import AppBarBack from '../Layout/AppBarBack';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Dialog, TextField } from '@mui/material';
import ReplyItemCard from './Components/ReplyItemCard2';
import ReplyItemService from './Services/ReplyItemService';
import TwoActionsDialog from '../Components/TwoActionsDialog';
import { CurrencyInput } from 'react-currency-mask';
import ReplyProductSearch from './Components/ReplyProductSearch';
import SlideUpTransition from '../Components/SlideUpTransitio';

const ReplyItem = () => {
    const quoteId = useParams().id;
    const quoteItemId = useParams().itemId;
    const [price, setPrice] = React.useState(0);
    const [observation, setObservation] = React.useState("");
    const [item, setItem] = React.useState({ product: {}, _id: "" });
    const [dirty, setDirty] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openProductDialog, setOpenProductDialog] = React.useState(false);
    const nav = useNavigate();

    React.useEffect(() => {
        ReplyItemService.getReplyItem(quoteId, quoteItemId).then((replyItem) => {
            setPrice(replyItem.price);
            setObservation(replyItem.observation);
            setItem(replyItem);
        });
    }, []);

    function putReply() {
        let data = { price, observation, replyProduct: item.product._id,replyItem:true };
        const p = ReplyItemService.putReplyItem(quoteId, quoteItemId, data);
        p.then((replyItem) => {
            nav(`/reply/${quoteId}`, { replace: true });
        });
        p.catch((error) => {
            alert(error.errors.map(e => e.msg).join("\n"));
        })
    }

    function notReply() {
        const data = {replyItem:false, replyProduct: item.product._id }
        ReplyItemService.putReplyItem(quoteId, quoteItemId,data ).then((replyItem) => {
            nav(`/reply/${quoteId}`, { replace: true });
        });
    }

    function goBack(shouldSave) {
        if (shouldSave == true)
            putReply();
        else if (shouldSave == false)
            nav(-1);
        else if (dirty)
            setOpenDialog(true);
        else
            nav(-1);
    }

    const updatePrice = (event, originalValue, maskedValue) => {
        setPrice(originalValue);
        setDirty(true);
    }

    const updateObservation = (o) => {
        setObservation(o);
        setDirty(true);
    }


    return (
        <>
            <AppBarBack goBack={goBack}>
                <Box sx={{ display: "flex", flexDirection: "column", m: 2, gap: 2 }}>
                    <ReplyItemCard product={item.product} price={0} _id={item._id} notReply={() => notReply()} selectProduct={() => setOpenProductDialog(true)} />
                    <CurrencyInput value={price}
                        onChangeValue={updatePrice}
                        InputElement={<TextField label="Valor Unitário" size="small" />}
                    /> <Alert severity="warning" onClose={() => { }}>Insira o preço UNITÁRIO.</Alert>
                    <TextField label="Observações" value={observation} multiline rows={4} fullWidth onChange={e => updateObservation(e.target.value)} />
                </Box>
            </AppBarBack>
            <TwoActionsDialog button1Text="Descartar"
                message="Deseja salvar as alterações?"
                title="Atenção"
                button2Text="Salvar"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                action1={() => goBack(false)}
                action2={() => goBack(true)} />

            <Dialog open={openProductDialog} onClose={() => setOpenProductDialog(false)} fullScreen TransitionComponent={SlideUpTransition}>
                <ReplyProductSearch onChange={(p) => {console.log(p)}} handleClose={() => setOpenProductDialog(false)}/>
            </Dialog>
        </>
    );
};

export default ReplyItem;