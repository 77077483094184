import { CheckCircle, ErrorOutline, PhotoTwoTone } from "@mui/icons-material";
import { Avatar, IconButton, Slide, Snackbar, styled, TextField, Typography } from "@mui/material";
import { Stack, Button } from "@mui/material";
import ReplyService from "./Services/ReplyServices";
import React from "react";
import UploadService from "../Services/UploadService";
import ProfileService from "../Settings/Services/ProfileService";

export default function Step3(props) {
    const [loading, setLoading] = React.useState(false);
    const [observation, setObservation] = React.useState(props.observation);
    const [ repliedBy,setRepliedBy] = React.useState(props.repliedBy);
    const [company,setCompany] = React.useState(props.company);
    const [profilePicture,setProfilePicture] = React.useState(props.profilePicture);
    const [showSnackbarSuccess, setShowSnackbarSuccess] = React.useState(false);
    const [showSnackbarError, setShowSnackbarError] = React.useState(false);

    function updateImage(event) {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            UploadService.uploadFile(file).then((r) => {
                setProfilePicture(r);
            });
        };
        reader.readAsDataURL(file);

    }

    function updateUser(){
        const  p = ReplyService.putReplyDetails(props.quoteId,repliedBy,company,profilePicture,observation);
        p.then((r) => {
            setLoading(true);
            setShowSnackbarSuccess(true);
            props.onChange(r);
        })
    }


    React.useEffect(() =>{
        ProfileService.getProfile().then((profile) =>{
            setRepliedBy(profile.name);
            setProfilePicture(profile.profilePicture);
            setCompany(profile.company);
        })
    },[])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showSnackbarSuccess}
                onClose={() => { props.nextStep() }}
                TransitionComponent={Slide}
                autoHideDuration={1200}
                message="Item salvo com sucesso"
                action={<IconButton><CheckCircle color="success" /></IconButton>}
            />

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showSnackbarError}
                onClose={() => { setShowSnackbarSuccess(false) }}
                TransitionComponent={Slide}
                autoHideDuration={1200}
                color='error'
                message="Erro ao salvar item"
                action={<IconButton><ErrorOutline color="error" /></IconButton>}
            />
            <div style={{ overflow: 'auto', height: "100%", paddingBottom: "100px" }}>
                <Stack m={2} spacing={2} >
                    <Typography variant="h6" sx={{alignSelf:"center"}}>Sua Logo</Typography>
                    <Button
                        disabled={loading}
                        component="label"
                        role={undefined}
                        tabIndex={-1}>
                        <Avatar sx={{ width: 80, height: 80, margin: "auto" }} alt="Remy Sharp" >
                            {profilePicture ? <img src={profilePicture} style={{ objectFit: "contain", height: "100%" }} /> : <PhotoTwoTone sx={{ height: "60px", width: "60px" }} />}
                        </Avatar>

                        <VisuallyHiddenInput
                            type="file"
                            onChange={updateImage}
                            multiple
                        />
                    </Button>
                    <TextField label="Seu Nome" name="name" value={repliedBy} required onChange={(e) => setRepliedBy(e.target.value)} disabled={loading} InputLabelProps={{ shrink: repliedBy }}/>
                    <TextField label="Nome da Empresa" name="company" value={company} required onChange={(e) => setCompany(e.target.value)} disabled={loading} InputLabelProps={{ shrink: company }}/>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={loading}
                            slots={{ openPickerIcon: CalendarIcon }}
                            slotProps={{ textField: { helperText: "Selecione a data de entrega." } }}
                            disablePast maxDate={dayjs(props.maxDeliveryDate)} value={dayjs(props.deliveryDate)} label="Data de entrega" onChange={(e) => props.setDeliveryDate(e)} />
                    </LocalizationProvider> */}
                    <TextField multiline rows={4}
                        disabled={loading}
                        id="outlined-basic"
                        label="Observações"
                        variant="outlined"
                        value={observation}
                        InputLabelProps={{ shrink: props.Observation }}
                        onChange={(e) => setObservation(e.target.value)}
                        helperText="Adicione informações relevantes a esta coleta." />

                    <Button fullWidth variant="outlined" disabled={loading} onClick={updateUser}> Continuar</Button>
                </Stack>
            </div>
        </div>
    );
}