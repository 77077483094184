import { Avatar, Divider, Grid, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import React from 'react';

const ItemSkeleton = () => {
    return (
        <>
        <ListItem>
            <ListItemAvatar>
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText primary={<Skeleton  animation="wave"  variant='text'></Skeleton>} secondary={<Skeleton  animation="wave"  variant='text'></Skeleton>} />
        </ListItem>
        <Divider/>
        </>
    );
};

export default ItemSkeleton;