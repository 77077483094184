import api from '../../API/api';

const CartService = {

    addToCart: (productId, quantity) => {
        const data = { product: productId, quantity }
        return api.put(`/cart/product`, data);
    },

    getCart: () => {
        return api.get('/cart');
    },

    deleteCart: () => {
        return api.delete('/cart');
    },

    loadCart: () => {
        return api.get('/cart?hidrate=true');
    },

    createQuote: (site, products) => {
        const items = products.map(p => ({ productId: p._id, quantity: p.quantity, observation: p.observation }));
        const data = { addressId: site._id, items };
        return api.post('/v1/quote', data);
    },

    copySharedCart: (sharedId) => {
        return api.get(`/sharedCart/${sharedId}`);
    },

    shareCart: () => {
        return new Promise((resolve, reject) => {
            api.post('/sharedCart').then((response) => {
                resolve(response.data);
            }).catch((e) => {
                reject(e.message);
            })
        });
    }
}
export default CartService;