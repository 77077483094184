import React from 'react';
import AppBarMenu from '../Layout/AppBarMenu';
import { List, ListSubheader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RepliesService from './Services/RepliesService';
import ReplyListItem from './Components/ReplyListItem';
import ReplyListSkeleton from './Components/ReplyListSkeleton';

const Replies = () => {
    const [replies, setReplies] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);
    const nav = useNavigate();

    React.useEffect(() => {
         const promise = RepliesService.getReplies();
         promise.then((quotes) => {
                setReplies(quotes);
                setIsLoading(false);
         })
    },[]);
    function openReply(id) {
        nav(`/reply/${id}`);
    }
    return (
        <AppBarMenu title="Coletas Recebidas">
            <List sx={{ width: '100%' }} component="nav" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Coletas Recebidas
                </ListSubheader>
            } >
                {isLoading ?
                [1,1,1,1,1].map((e,i) => <ReplyListSkeleton key={i}/>)
                :replies.map((e, idx) => <ReplyListItem onClick={() => openReply(e.replyTo)} created_at={e.created_at} title={e.title} status={e.status} subtitle={e.subTitle} key={idx} />)}
            </List>
        </AppBarMenu>
    );
};

export default Replies;