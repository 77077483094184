import React from 'react';
import { Box, Stack, Tooltip, Button, Container, Avatar, Typography, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Skeleton, CardActions, FormGroup } from '@mui/material';

import dayjs from "dayjs";
import { LocationOnTwoTone } from '@mui/icons-material';
const Step1 = (props) => {
    const maxDate = dayjs(props.maxDeliveryDate).format("DD/MM/YYYY");
    const [acceptAddress, setAcceptAddress] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [reply, setReply] = React.useState(props.reply);
    return (
        <div>
            <Container sx={{ pb: "100px" }} >
                <Card >
                    <CardHeader title="Endereço" subheader="Confirme se você atende a este endereço." action={<LocationOnTwoTone />}></CardHeader>
                    {isLoading ?
                        <CardContent >
                            <Skeleton animation="wave" variant="text" />
                            <Skeleton animation="wave" variant="text" />
                            <Skeleton animation="wave" variant="text" />
                        </CardContent> :
                        <CardContent>
                            <Typography variant="body1">{reply.delivery.street}, n° {reply.delivery.number}</Typography>
                            <Typography variant="body1">{reply.delivery.complement}</Typography>
                            <Typography variant="body1">{reply.delivery.zipCode}, {reply.delivery.city}, {reply.delivery.uf}</Typography>
                        </CardContent>
                    }
                    <CardContent>
                            <Typography variant="body1">Você atende nessa região?</Typography>
                        </CardContent>
                    <CardActions sx={{ pl: 2 }}>
                        <Button fullWidth variant="outlined" color="error">Não</Button>
                        <Button fullWidth variant="outlined" onClick={props.nextStep}>Sim</Button>
                    </CardActions>

                </Card>
            </Container>
        </div >
    );
};
export default Step1;