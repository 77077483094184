import React from 'react';
import ScrollBox from './ScrollBox';
import SiteCard from './SiteCard';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

function NewSiteCard(props) {
    const theme = useTheme();

    return (
        <Card onClick={props.onClick} sx={{ m: 2, height: "120px", width: "200px", boxShadow: "5", borderRadius: "15px", backgroundColor: `${theme.palette.grey[200]}`, alignContent: "center" }}>
            <Box sx={{display:"flex",justifyContent:"center"}}>
                <Add color="primary"/>
            </Box>
            <Box sx={{display:"flex",justifyContent:"center"}}>
                <Typography variant="subtitle1" component="div" color={"primary"}>
                    Nova Obra
                </Typography>   
            </Box>
        </Card>)
}

const AddressSelector = (props) => {
    const addresses = props.addresses ?? [];
    const [selectedSite, setSelectedSite] = React.useState(0);

    const onSelect = (index) => {
        setSelectedSite(index);
        props.onChange(addresses[index]);
    };
    

    function renderProps(){
        return addresses.map((site, index) => (<SiteCard key={index} logradouro={site.street} numero={site.number} municipio={site.city} uf={site.uf} obra={site.reference} selected={site._id === props.selected?._id} onClick={() => onSelect(index)} />));
    }

    return (
        <ScrollBox>
             {renderProps()}    
            <NewSiteCard onClick={props.onNewSite} />
        </ScrollBox>
    );
};

export default AddressSelector;