import { Container, Typography, Grid } from '@mui/material';
import React from 'react';
import QuoteService from './Services/QuoteService';
import ProductOptionCard from './Components/ProductOptionCard';
import { useParams } from 'react-router-dom';
import AppBarBack from '../Layout/AppBarBack';
import ProductOptionsCardSkeleton from './Components/ProductOptionCardSkeleton';

const ItemReplies = (props) => {
    
    const quoteId = useParams().id;
    const quoteItemId = useParams().itemId;
    const [quoteItem, setQuoteItem] = React.useState({replies:[],hasSelectedReply:false,_id:null});
    const [isLoading,setIsLoading] = React.useState(false);
    const [isLoadingItems,setIsLodingItems] = React.useState(true);

    const selectOption = (replyId) => {
        replyId = quoteItem?.hasSelectedReply ? null : replyId;
        setIsLoading(true);
        QuoteService.selectReplyItem(quoteId,quoteItem._id,replyId).then((item) => {
            setQuoteItem(item);
            setIsLoading(false);
        });
    }

    React.useEffect(() => {
        const promise = QuoteService.getQuoteItem(quoteId, quoteItemId);
        promise.then((i) => {
            setQuoteItem(i);
            setIsLodingItems(false);
        })
    },[])

    function renderReplyItems(){
        if(quoteItem.replies.length === 0) return <Typography variant="body1" >Este produto não obteve nenhuma resposta.</Typography>
        return quoteItem.replies.map((e, i) =>
            <ProductOptionCard product={e} key={Math.random()} selectOption={selectOption} isLoading={isLoading}/>
        )
    }

    return (
        <AppBarBack title="Respostas">
            <Container sx={{mt:2}}>
                <Grid container>
                    {
                        isLoadingItems ?
                        [1,1,1,1,1].map((e,i) => <ProductOptionsCardSkeleton key={i}/>) :
                        <Grid container spacing={2}>
                        {renderReplyItems()}  </Grid>
                    }</Grid>
            </Container>
        </AppBarBack>
    );
};

export default ItemReplies;