import api from "../../API/api";
import moment from "moment";

function reply2ReplyList(reply){
    return {
        _id: reply._id,
        replyTo: reply.replyTo,
        title: `#${reply.replyTo.substring(reply.replyTo.length-6).toUpperCase()}`,
        subTitle:`${reply.items.map((i) => i.product.product).join(", ")}`,
        created_at: reply.created_at,
        status: reply.status,
    }
  }

const RepliesService = {
    getReplies: () => {
        return new Promise((resolve, reject) => {
            const p = api.get(`/v1/replies`);
            p.then((response) => {
                const r = response.data.map((q) => reply2ReplyList(q));
                resolve(r)
            });
        });
    },
}

export default RepliesService;