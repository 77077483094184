import { WhatsApp } from '@mui/icons-material';
import { Avatar, Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, useNavigate, useSearchParams } from 'react-router-dom';

const Welcome = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const nav = useNavigate();
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) nav("/quote");

    })
    const goToLogin = ()=>{
        let url = searchParams.get('url');
        url = url? `/login?url=${url}`: '/login'
        nav(url)
    }
    return (
        <Container sx={{ height: "80vh", display: "flex", width: "90vw", alignItems: "center" }}>
        <Stack sx={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }} spacing={2}>
            <Box>
                <Avatar sx={{ width: 200, height: 200, margin: "auto" }} alt="Remy Sharp" src="/gettaquote.gif" />
            </Box>
            <Typography variant="h5" textAlign={"center"}> Bem vindo ao Coletefy, seu sistema fácil de coleta de preços. </Typography>
            <Button variant="outlined" color="success" fullWidth endIcon={<WhatsApp/>} onClick={goToLogin}>Entrar com Whatsapp</Button>
        </Stack>


    </Container   >
    );
};


export default Welcome;