import api from "../../API/api";

const ReplyService = {
    getReply: (quoteId) => {
        return new Promise((resolve, reject) => {
            const p = api.get(`/v1/reply/${quoteId}`);
            p.then((response) => {
                resolve(response.data)
            });
        });
    },

    postReply: (quoteId) => {
        return new Promise((resolve, reject) => {
            const p = api.post(`/v1/reply/${quoteId}`);
            p.then((response) => {
                resolve(response.data)
            });
        });
    },

    putReplyDetails: (quoteId,repliedBy,company,profilePicture,observation) => {
        return new Promise((resolve, reject) => {
            const p = api.put(`/v1/reply/${quoteId}`,{repliedBy,company,profilePicture,observation});
            p.then((response) => {
                resolve(response.data)
            });
        });
    },

}

export default ReplyService;