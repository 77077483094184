import { ShoppingCart, WhatsApp } from '@mui/icons-material';
import { Avatar, Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { Route, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CartService from './Services/cartService';

const SharedCart = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const nav = useNavigate();
    const sharedId = useParams().sharedId;

    function copyCart(){
        CartService.copySharedCart(sharedId).then(() => {
            nav("/cart");
        })
        .catch(() =>{
            alert("Não foi possível acessar o carrinho.")
        })
    }

    return (
        <Container sx={{ height: "80vh", display: "flex", width: "90vw", alignItems: "center" }}>
            <Stack sx={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }} spacing={2}>
                <Box>
                    <Avatar sx={{ width: 200, height: 200, margin: "auto" }} alt="Remy Sharp" src="/gettaquote.gif" />
                </Box>
                <Typography variant="h5" textAlign={"center"}> Você recebeu um carrinho de compras. </Typography>
                <Button variant="outlined" color="primary" fullWidth endIcon={<ShoppingCart />} onClick={copyCart}>Acessar Carrinho</Button>
            </Stack>


        </Container   >
    );
};


export default SharedCart;