import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './ScrollBox.css';

function ScrollBox({ children }) {
    const c = children.flat();
    const refs = c.map(() => React.createRef());

    function childClick(i) {
        refs[i].current?.scrollIntoView({behavior: 'smooth',block:"center",inline:"center"});
    }
    return (
        <div className="scroll-box">
            <div className="scroll-box__wrapper">
                <div className="scroll-box__container" role="list">
                    {c.map((child, i) => (
                        <div className="scroll-box__item" role="listitem" key={`scroll-box-item-${i}`} onClick={(e) => childClick(i)} ref={refs[i]}>
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

ScrollBox.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ScrollBox;
