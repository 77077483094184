import React, { useState } from 'react';
import SearchAppBar from '../../Layout/SearchAppBar';
import ProductSearch from '../../Product/ProductSearch';
import  SearchIcon  from '@mui/icons-material/Search';
import { AppBar, Toolbar, IconButton, Typography, debounce, styled, alpha, InputBase } from '@mui/material';
import { Close } from '@mui/icons-material';

const ReplyProductSearch = ({ onChange,handleClose }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const changeSearch = debounce((e) => {
        setSearchTerm(e.target.value);
    }, 500);


    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
        alignItems: 'flex-start',
      }));
      
      
      const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: "20px",
        width: 'calc(100vw - 40px)',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '20ch',
          },
        },
      }));

    return (
        <>
            <AppBar position="sticky" sx={{ height: 110 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                        Buscar Produtos
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </Toolbar>
                <Search onChange={changeSearch}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Buscar Produto..."
                        inputProps={{ 'aria-label': 'search' }}
                    /></Search>
            </AppBar>
            <ProductSearch search={searchTerm} productAdd={() => { }} productOpen={onChange} />
        </>
    );
};

export default ReplyProductSearch;