import { BookmarkTwoTone, BookmarkBorderTwoTone, Warning, ExpandMore } from '@mui/icons-material';
import { Avatar, Rating, IconButton, Box, Typography, CardActions, Chip, CardContent, Card, CardHeader, Alert, Button, Collapse, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ReplyItemCard = (props) => {
    const [option, setOption] = React.useState(props.product);
    const quoteId = useParams().id;
    const nav = useNavigate();
    function openReply() {
        nav(`/reply/${quoteId}/item/${props._id}`); 
    }
    

    return (

        <Grid item xs={12} md={12} lg={12} key={option._id}>
            <Card >
                <Box sx={{mt:2}}>
                    <Box sx={{ ml: 2, display: "flex", flexDirection: "row", gap: 1 }}>
                        <Avatar src={props.product.picture} />
                        <Box sx={{ ml: 2, display: "flex", flexDirection: "column", flexGrow: 0 }}>
                            <Typography variant="body1" sx={{ overflow: "hidden", WebkitLineClamp: 2, textOverflow: "ellipsis" }}>{props.product.product}</Typography>
                            <Typography variant="caption">{props.product.brand}</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ m:2, display: "flex", flexDirection: "column",justifyContent:"space-between", alignSelf: "center", flexGrow: 1,gap:1 }}>
                        {/* <Button variant='outlined' fullWidth onClick={() => props.selectProduct()}>Oferecer produto alternativo</Button> */}
                        <Button variant='outlined' color="error" onClick={() => props.notReply()} fullWidth>Não Responder</Button>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};

export default ReplyItemCard;